import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { textFieldStyle } from './TextField.style';

export const numberFieldStyle = ({ theme, prefix = '' }) => css`
  ${textFieldStyle({ theme, prefix })}
  input {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export default styled.div`
  ${numberFieldStyle};
`;
