import React, { useEffect, useMemo, useState } from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import HubSpotForm from '../../components/HubSpotForm';
import Heading from '../../components/global/Heading';
import ModuleStyle from './module.style';

const HubspotFormModule = ({
  variant,
  title,
  form,
  wlLayout,
  boxPosition,
  ...p
}) => {
  const content = form?.content;

  const className = { aliceBlue: 'variant-blue' }[variant?.content];
  const sticky = boxPosition.content === 'secondary';

  return (
    <ModuleStyle
      wlLayout={wlLayout && wlLayout !== 'plain' ? wlLayout : 'full'}
      className={className}
      sticky={sticky}
      {...p}
    >
      {!!title && <Heading title={title} />}
      <HubSpotForm dangerouslySetInnerHTML={{ __html: content }} formTitle={form.title} />
    </ModuleStyle>
  );
};
export default HubspotFormModule;

registerModule({ HubspotForm: Self });
