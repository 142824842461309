import styled from '@emotion/styled';
import StickyableContainer from '../../components/StickyableContainer';

export default styled(StickyableContainer)`
  &.variant-blue {
    .layout-box {
      padding: 30px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.aliceBlue};
    }
  }
`;
